import * as React from "react"
import "../components/sub.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PhotoMenu from "../components/photomenu"
import kv0 from "../images/careers.png"

const Careers = () => {
  return (
    <Layout>
      <Seo title="採用情報 - CAREERS" />
      <img className="img_fluid w-100 sub_kv" src={kv0} />
      <div className="container">
        <div className="title-content">
          <h1 className="main-title syouei-font-en syouei-font-bold">
            CAREERS
          </h1>
          <h2 className="sub-title-ja syouei-font-bold">採用情報</h2>
        </div>
      </div>
      <div className="container d-block d-md-none">
        <div className="company">
          <div className="item-title">募集要項</div>
        </div>
      </div>
      <div className="company-content">
        <div className="container">
          <div className="company mt-5">
            <div className="row">
              <div className="col-lg-3 d-none d-md-block">
                <div className="item-title">募集要項</div>
              </div>
              <div className="col-lg-9">
                <div className="row company-info">
                  <div className="col-lg-4 company-titele">就業・雇用形態</div>
                  <div className="col-lg-8 company-overview">正社員</div>
                  <div className="col-lg-4 company-titele">産業</div>
                  <div className="col-lg-8 company-overview">建設業</div>
                  <div className="col-lg-4 company-titele">就業時間</div>
                  <div className="col-lg-8 company-overview">
                    8：00～17：00（現場による）
                  </div>
                  <div className="col-lg-4 company-titele">休憩時間</div>
                  <div className="col-lg-8 company-overview">
                    120分（昼休み60分、途中午前午後共に30分休憩あり）
                  </div>
                  <div className="col-lg-4 company-titele">時間外</div>
                  <div className="col-lg-8 company-overview">
                    あり 月平均15時間、現場により早出あり
                  </div>
                  <div className="col-lg-4 company-titele">賞与</div>
                  <div className="col-lg-8 company-overview">
                    会社、個人実績による
                  </div>
                  <div className="col-lg-4 company-titele">休日</div>
                  <div className="col-lg-8 company-overview">
                    週休二日
                    <br />
                    GW、夏季、冬季連休あり
                    <br />
                    上記以外の休み相談可
                  </div>
                  <div className="col-lg-4 company-titele">年間休日数</div>
                  <div className="col-lg-8 company-overview">107日など</div>
                  <div className="col-lg-4 company-titele">就業場所</div>
                  <div className="col-lg-8 company-overview">
                    &#12306;207-0033 東京都東大和市芋窪3-1726（資材置場）
                    <br />
                    他、都内および関東近県の現場
                  </div>
                  <div className="col-lg-4 company-titele">転勤</div>
                  <div className="col-lg-8 company-overview">なし</div>
                  <div className="col-lg-4 company-titele">従業員数</div>
                  <div className="col-lg-8 company-overview">
                    企業全体：5人 うち就業場所：5人 うち男性：4人 うち女性：1人
                  </div>
                  <div className="col-lg-4 company-titele">加入保険</div>
                  <div className="col-lg-8 company-overview">
                    雇用 労災 健康 厚生
                  </div>
                  <div className="col-lg-4 company-titele">入居可能住宅</div>
                  <div className="col-lg-8 company-overview">あり</div>
                  <div className="col-lg-4 company-titele">マイカー通勤</div>
                  <div className="col-lg-8 company-overview">可</div>
                  <div className="col-lg-4 company-titele">通勤手当</div>
                  <div className="col-lg-8 company-overview">実費支給</div>
                  <div className="col-lg-4 company-titele">仕事の内容</div>
                  <div className="col-lg-8 company-overview">
                    ガソリンスタンドや給油施設の新設工事、セルフ切替工事、各所補修メンテナンス工事などを主に作業していただきます。
                    <br />
                    入社後は先輩職人について手元見習いとして作業をしていただきます。
                  </div>
                  <div className="col-lg-4 company-titele">学歴</div>
                  <div className="col-lg-8 company-overview">不問</div>
                  <div className="col-lg-4 company-titele">
                    必要な免許・資格
                  </div>
                  <div className="col-lg-8 company-overview">不問</div>
                  <div className="col-lg-4 company-titele">
                    求人条件にかかる特記事項
                  </div>
                  <div className="col-lg-8 company-overview">
                    &#12539;試用期間３ヵ月あり（同条件）
                    <br />
                    &#12539;技術取得、資格取得に応じて昇給あり。
                  </div>
                  <div className="col-lg-4 company-titele">その他PR</div>
                  <div className="col-lg-8 company-overview">
                    &#12539;作業に必要な資格取得の支援制度があります。積極的にバックアップします。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="syouei-sub syouei-careers">
        <PhotoMenu />
      </div>
    </Layout>
  )
}

export default Careers
